import { get, post, put, del } from "../../api/MainClient";


export const AffiliateRegister = async(data)=>{
    const register = await post(`/affiliate/signup/`,data)
    .then(resp=>{
        return resp
    }).catch(error=>{
        return error.response
    })
    return register
};

export const Affiliateget = async()=>{
    const register = await get(`/affiliate/signup/`)
    .then(resp=>{
        return resp
    }).catch(error=>{
      return error.response
  })
  return register
};


export const getAffiliateSales = async(searchKey,startDate,endDate,pageNo,itemPerPage)=>{
    const proSales = await get(`/affiliate/product-sale/?max_date=${endDate}&min_date=${startDate}&page=${pageNo}&search=${searchKey}&page_size=${itemPerPage}`)
    .then(resp=>{
        return resp
    }).catch(error=>{
        return error.response
    })
    return proSales
};

export const getAffiliateSalesTotalEarning = async()=>{
  const proSales = await get(`/affiliate/total-earning/`)
  .then(resp=>{
      return resp
  }).catch(error=>{
      return error.response
  })
  return proSales
};




export const getAffiliateSignUp = async(searchKey,startDate,endDate,pageNo,itemPerPage)=>{
    const affiSign = await get(`/affiliate/signup-analysis/?min_date=${startDate}&max_date=${endDate}&page=${pageNo}&search=${searchKey}&page_size=${itemPerPage}`)
    .then(resp=>{
        return resp
    }).catch(error=>{
        return error.response
    })
    return affiSign
};


export const Postafftc = async (data) => {
    const teamsandcon = await put("/affiliate/terms/", data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response;
      });
    return teamsandcon;
  };

  export const getaffdata = async () => {
    const teamsandcon = await get("/affiliate/signup/")
      .then((resp) => {
        return resp.data;
      })
     
    return teamsandcon;
  };


  export const getAffiInfo = async () => {
    const teamsandcon = await get("/affiliate/dashboard/")
      .then((resp) => {
        return resp.data;
      })
     
    return teamsandcon;
  };
  
  
  export const PostBankDetails = async (data) => {
    const bankDetails = await post("/affiliate/bank-details/", data)
      .then((resp) => {
        return resp;
      })
    return bankDetails;
  };


export const getaffiliatebank = async () => {
  const bank = await get("/affiliate/bank-details/")
    .then((resp) => {
      return resp.data;
    })
   
  return bank;
};
  

export const PayoutdetailsAff = async (searchKey,startDate,endDate,selected,pageNo,itemPerPage) => {
    const payout = await get(`/affiliate/earning/?status=${selected}&max_date=${startDate}&min_date=${endDate}&page=${pageNo}&search=${searchKey}&page_size=${itemPerPage}`)
      .then((resp) => {
        return resp.data;
      })
     
    return payout;
  };


  export const Promotionimages = async () => {
    const promotion = await get("/affiliate/promotions/")
      .then((resp) => {
        return resp.data;
      })
     
    return promotion;
  };


  // ==========Faq===========

  export const FaqSubmit = async (data) => {
    const faq = await post("/affiliate/faq-submit/", data)
      .then((resp) => {
        return resp;
      })
    return faq;
  };
 
  export const faqget = async () => {
    const faq = await get("/contacts/faq/category/issues/8/")
      .then((resp) => {
        return resp.data;
      })
     
    return faq;
  };

  
  export const getBankStatus = async () => {
    const faq = await get("affiliate/bank-status/")
      .then((resp) => {
        return resp.data;
      })
     
    return faq;
  };
